import React, { Component } from 'react';
import { Alert, Badge, Col, Container, Row, Label, FormFeedback, Input, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { withRouter, Link } from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { CreateConsulent, Loading, /*DeleteConsulent*/ Utils } from "custom";

import { ConsulentAPI, UserAPI } from "api";

class ConsulentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            cell: '',
            id: '',
            active: false,
            consulent: [],
            loading: true,
            error: false,
            errors: null,
            showCreateModal: false,
            showEditModal: false
        };
    }

    componentDidMount() {
        this.getConsulent();
    }

    getConsulent() {
        ConsulentAPI.list().then((consulent) => {
            this.setState({ loading: false, error: false, consulent: consulent });
        }).catch(() => {
            this.setState({ error: true });
        })
    }



    handleEditmodal = (obj) =>{
        this.setState({...obj, showEditModal:true})
    }

    handleCloseEditmodal = () =>{
        this.setState({
            firstname: '',
            lastname: '',
            email: '',
            cell: '',
            id: '',
            active: false,
            showEditModal: false,
            deleteModal: false
        })
    }

    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    UserAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getConsulent();
            }
        })
    }

    editSaveConsulent = async () =>{
        try {
            var user = {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                cell: this.state.cell,
                id: this.state.id,
                active: this.state.active,
            }
            // console.log('user',user);
            ConsulentAPI.edit(user).then((data)=>{
                this.handleCloseEditmodal()
                this.getConsulent();
            }).catch((error)=>{
                console.log(error)
                this.setState(({showEditModal:false}));
            })
        } catch (error) {
            this.setState({ error: true });
        }
    }

    deleteConsulent = async () => {
        try {
            ConsulentAPI.remove(this.state.id).then(()=> {
                this.getConsulent();
                this.setState(({deleteModal:false}));
            }).catch((error)=>{
                this.setState(({deleteModal:false}));
                console.log('error',error);
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        let errors = this.state.errors;
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista consulenti.</Alert>
            </React.Fragment>
        }
        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{ marginBottom: 10 }}>
                    <CreateConsulent onResolve={this.getConsulent.bind(this)}/>
                </div>
                {(this.state.loading) ? <Loading /> : <Grid
                    data={this.state.consulent}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            sort: false,
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'lastname',
                            name: 'Nominativo',
                            sort: true,
                            formatter: (cell, row, t) =>_(<span className="text-nowrap">{row.cells[2].data} {cell}</span>)
                        },
                        {
                            id: 'firstname',
                            name: 'Nome',
                            hidden:true
                        },
                        {
                            id: 'email',
                            name: 'Email',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'cell',
                            name: 'Cellulare',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'isRegistered',
                            hidden: true
                        },
                        {
                            id: 'active',
                            name: 'Stato',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell) ? <Badge color="success" >Attivo</Badge> : ((row.cells[5].data) ? <Badge color="danger" >Non Attivo</Badge> : <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge>)}
                            </span>)
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(
                            <ul className="list-inline hstack gap-2 mb-0">
                                <li className="list-inline-item cursor-pointer"
                                    onClick={()=> {
                                        var obj = {
                                            id:cell,
                                            firstname: row.cells[2].data,
                                            lastname: row.cells[1].data,
                                            email: row.cells[3].data,
                                            cell: row.cells[4].data,
                                            active: row.cells[5].data,
                                        }
                                        this.handleEditmodal(obj)}
                                    }
                                >
                                    <i className="ri-pencil-fill fs-20 text-info"></i>
                                </li>
                                {/*<li className="list-inline-item cursor-pointer" onClick={()=> this.setState({deleteModal: true, id:cell})}>*/}
                                {/*    <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>*/}
                                {/*</li>*/}
                            </ul>)
                        }
                    ]}
                    style={{ table: { 'white-space': 'nowrap' } }}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10 }}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Consulenti',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun consulente trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}

                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showEditModal} scrollable={true} size="lg" className={'modal-dialog-scrollable modal-md'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>MODIFICA CONSULENTI</h3>
                        </div>
                        <div>
                            <Row>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Nome
                                        </Label>
                                        <Input
                                            type="text"
                                            value={this.state.firstname}
                                            invalid={Boolean(errors?.firstname)}
                                            onChange={(e) => this.setState({ firstname: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.firstname}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Cognome
                                        </Label>
                                        <Input
                                            type="text"
                                            value={this.state.lastname}
                                            invalid={Boolean(errors?.lastname)}
                                            onChange={(e) => this.setState({ lastname: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.lastname}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            value={this.state.email}
                                            invalid={Boolean(errors?.email)}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.email}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-3'>
                                        <Label className='form-label'>
                                            Telefono
                                        </Label>
                                        <Input
                                            type="tel"
                                            value={this.state.cell}
                                            invalid={Boolean(errors?.cell)}
                                            // onChange={(e)=> console.log(e.target.value)}
                                            onChange={(e) => this.setState({ cell: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.cell}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                                        <Input className="form-check-input" type="checkbox" role="switch"  checked={this.state.active}
                                            onChange={(e) => this.setState({active: e.target.checked})}/>
                                        <Label className="form-check-label">Attiva / Disattiva Utente</Label>
                                    </div>
                                    {/* {(!this.props.isRegistered) ? <Alert color="warning" >L'utente deve ancora completare la registrazione</Alert> : null} */}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.editSaveConsulent}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.handleCloseEditmodal()}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.deleteModal} scrollable={true} centered className={'modal-dialog-scrollable modal-md'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center'>
                            <h3 className='mb-1'>ELIMINA CONSULENTE</h3>
                            <h5>
                                sei sicuro di voler eliminare questo consulente?
                            </h5>
                        </div>
                    </ModalBody>
                    <ModalFooter className='justify-content-center'>
                        <Button
                            color='primary'
                            onClick={this.deleteConsulent}
                        >
                            Si, elimina
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.setState({ deleteModal: false })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(ConsulentTable);

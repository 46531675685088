import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Col,
    Container,
    Row,
    Input,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    FormText,
    FormFeedback,
    Card, CardBody
} from 'reactstrap';
import { withRouter, Link } from "react-router-dom";

import {ClassRoomTrialAPI, ConsulentAPI, LeadAPI, LeadReminderAPI, ModuleAPI} from 'api'
import {Utils, Loading, Params /*Loading, ImportLead, GestLead, DeleteLead,  AssignLead*/} from "custom";

import Select, { components } from "react-select";
import LeadAdd from './add';
import LeadTableRender from './LeadTableRender';
import CountUp from "react-countup";
import LeadSetting from "./LeadSetting";
import {Italian} from "flatpickr/dist/l10n/it";
import Flatpickr from "react-flatpickr";

import * as XLSX from 'xlsx';



const moment = require("moment");

class LeadTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leads: [],
            leadsFiltered: null,
            reminders: [],
            loading: true,
            loadingAssign: false,
            error: false,
            errors: false,
            importModal: false,
            consulent: [],
            selectedConsulent: null,
            filterConsulent: [],
            loadingImport: false,
            deleteModal: false,
            selectedFile: null,
            filters: [],
            leadIds: [],
            deleteLeadId: null,
            modules: [],
            moduleUpload: null
        };

        this.selectedLeads = [];
    }

    componentDidMount() {
        this.getLeads();
        if (Utils.getUserData().role === "ADMIN") {
            this.getConsulent()
        }
    }

    getLeads(range) {
        this.selectedLeads = [];
        this.setState({leadIds:[]})

        let start_date = (range) ? range.start_date : null;
        let end_date = (range) ? range.end_date : null;

        if (!range)
            return;

        LeadAPI.list(start_date, end_date).then(async (res) => {

            let modules = [];

            if (Utils.getUserData().role === "ADMIN") {
                modules = await ModuleAPI.list();
            }

            this.setState({ loading: false, error: false, leads: res.list, reminders: res.reminders, modules: modules });

            setTimeout(() => {
                Params.addHandlePaginator()
            }, 1000)

        }).catch(() => {
            this.setState({ error: true });
        })
    }

    onFilterLeads(filters, leadsFiltered) {

        let leads = (leadsFiltered) ? leadsFiltered : this.state.leads;

        if (filters.length > 0) {
            if (filters.includes("converted"))
                leads = leads.filter((lead) => lead.status === "CONVERTED")
            if (filters.includes("trials"))
                leads = leads.filter((lead) => lead.status === "TRIAL")
            if (filters.includes("trialCheck"))
                leads = leads.filter((lead) => (lead.trial_check && moment(lead.trial_date).isBefore(moment())))
            if (filters.includes("trialUncheck"))
                leads = leads.filter((lead) => (!lead.trial_check && lead.id_trial !== null && moment(lead.trial_date).isBefore(moment())))
            if (filters.includes("remarketing"))
                leads = leads.filter((lead) => lead.status === "REMARKETING")
            if (filters.includes("recall"))
                leads = leads.filter((lead) => lead.status === "APPOINTMENT")
            if (filters.includes("unreply"))
                leads = leads.filter((lead) => lead.status === "UNREPLY")
            if (filters.includes("ended"))
                leads = leads.filter((lead) => lead.status === "ENDED")
            if (filters.includes("whatsapp"))
                leads = leads.filter((lead) => lead.referral_platform === "WHATSAPP")
            if (filters.includes("manual"))
                leads = leads.filter((lead) => lead.referral_platform === "MANUAL")
            if (filters.includes("bm"))
                leads = leads.filter((lead) => lead.referral_platform === "META")
            if (filters.includes("funnel"))
                leads = leads.filter((lead) => lead.referral_platform === "FUNNEL")
            if (filters.includes("report"))
                leads = leads.filter((lead) => lead.referral_platform === "REPORT")
            if (filters.includes("waiting"))
                leads = leads.filter((lead) => (lead.status === null && lead.id_lead_consulent !== null))
            if (filters.includes("unassigned"))
                leads = leads.filter((lead) => (lead.id_lead_consulent === null))
            if (filters.includes("funnelBm"))
                leads = leads.filter((lead) => (lead.referral_platform === "FUNNEL" || lead.referral_platform === "META"))
            if (filters.includes("working"))
                leads = leads.filter((lead) => (lead.status === "WORKING"))

            if (this.state.filterConsulent.length > 0)
                leads = leads.filter((lead) => (this.state.filterConsulent.map((m) => m.id).includes(lead.id_lead_consulent)))

            this.setState({leadsFiltered: leads, filters: filters})
        } else {
            if (this.state.filterConsulent.length > 0) {
                leads = leads.filter((lead) => (this.state.filterConsulent.map((m) => m.id).includes(lead.id_lead_consulent)))
            } else {
                leads = null;
            }

            this.setState({leadsFiltered: leads, filters: filters})
        }
    }

    filterLeadsOnConsulent(ids_consulent) {
        if (ids_consulent.length > 0) {
            let leads = this.state.leads;
            leads = leads.filter((lead) => (ids_consulent.includes(lead.id_lead_consulent)));


            if (this.state.filters.length > 0) {
                this.onFilterLeads(this.state.filters, leads);
                return;
            }

            this.setState({leadsFiltered: leads})
        } else {
            if (this.state.filters.length > 0) {
                this.setState({filterConsulent: []}, () => this.onFilterLeads(this.state.filters))
                return;
            }
            this.setState({leadsFiltered: null, filterConsulent: []})
        }
    }

    getConsulent() {
        ConsulentAPI.list().then((data) => {
            // data.push({value})
            this.setState({ consulent: data });
        }).catch(() => {
            this.setState({ error: true });
        })
    }

    onSelectLead = (id, state) => {
        let selectedLeads = this.selectedLeads;
        if (state) {
            selectedLeads.push(id);
        } else {
            for (let i = 0; i < selectedLeads.length; i++) {
                if (selectedLeads[i] === id) {
                    selectedLeads.splice(i, 1);
                }
            }
        }
        function addAnotherPizza(state, props) {
            return {
                leadIds: selectedLeads,
            }
        }
        this.setState(addAnotherPizza)
    }



    getReferralLabel(referral) {
        switch (referral) {
            case "META":
                return (Utils.getUserData().role === "ADMIN") ? <i className="ri-facebook-box-fill align-middle fs-20 text-secondary" /> : <i className="ri-global-fill align-middle fs-20 text-primary" />;
            case "MANUAL":
                return <i className="ri-git-repository-commits-fill align-middle fs-20 text-info" />;
            case "FUNNEL":
                return <i className="ri-global-fill align-middle fs-20 text-primary" />;
            case "WHATSAPP":
                return <i className="ri-whatsapp-fill align-middle fs-20 text-success" />;
            case "REPORT":
                return <i className="mdi mdi-bullhorn align-middle fs-20 text-danger" />;
            default:
                return null
        }
    }

    onCloseCreate = () => {
        this.setState({
            createModal: false,
            createName: null,
            createEmail: null,
            createPhone: null,
            createCountry: null,
        })
    }

    uploadLeadFile = (file) => {
        // console.log('file',file);
        if (file) {

            if (this.state.moduleUpload === null)
                return;

            const formdata = new FormData();
            formdata.append('csv', file[0])
            this.setState({loadingImport: true});
            LeadAPI.upload(formdata, this.state.moduleUpload.value).then((data) => {
                this.setState({ importModal: false, loadingImport: false })
                global.SweetAlert.fire({
                    title: 'File Importato',
                    text: data.leadCount + ' leads importati correttamente',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                });
                this.getLeads();
            })
        }
    }

    onAssignLead = () => {
        var obj = {
            id_consulent: this.state.selectedConsulent?.id ?? null,
            id_leads: this.selectedLeads
        }
        if (this.state.selectedConsulent?.id && this.selectedLeads.length > 0) {
            LeadAPI.assign(obj).then((data) => {
                console.log('data', data);
                global.SweetAlert.fire({
                    title: 'Assegnato',
                    text: 'Lead assegnato correttamente',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                });
                this.getLeads()
                this.selectedLeads = [];
                this.setState({selectedConsulent:null})
            }).catch(() => {
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante l\'assegnazione del lead',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                });
            })
        }
    }

    onRemoveAssignedLead = () => {
        var obj = {
            id_consulent: this.state.selectedConsulent?.id ?? null,
            id_leads: this.selectedLeads
        }
        if (this.selectedLeads.length > 0) {
            LeadAPI.assign(obj).then((data) => {
                console.log('data', data);
                global.SweetAlert.fire({
                    title: 'Completato',
                    text: 'LE ASSEGNAZIONI DEI LEAD SONO STATE RIMOSSE CORRETTAMENTE',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                });
                this.getLeads()
                this.selectedLeads = [];
                this.setState({selectedConsulent:null})
            }).catch(() => {
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante assegnazione del lead',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                });
            })
        }
    }


    signCheck = (id_classroom, id_partecipant) => {
        const self = this;
        global.SweetAlert.fire({
            title: "Segna presenza lezione di prova",
            text: 'Sei veramente sicuro di voler segnare la presenza alla lezione di prova? Questa azione è irreversibile',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    ClassRoomTrialAPI.sign(id_classroom, id_partecipant).then(() => {
                        self.getLeads()
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile segnare la presenza alla lezione di prova. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Presenza segnata correttamente!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                // self.detailPartecipants(id_classroom, 1);
            }
        })
    }

    checkReminder(id) {

        const self = this;

        global.SweetAlert.fire({
            title: "Completa Promemoria",
            text: "Vuoi veramente contrassegnare il promemoria come completato?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    LeadReminderAPI.confirm({
                        id: id
                    }).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile completare il promemoria. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Promemoria completato!',
                    text: 'Promemoria completato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                let reminders = self.state.reminders;
                reminders = reminders.splice(id, 1);
                self.setState({reminders: reminders});
            }
        })
    }

    exportToExcel() {

        let leads = [];

        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let data = (this.state.leadsFiltered !== null) ? this.state.leadsFiltered : this.state.leads;

        for (let i = 0; i < data.length; i++) {
            leads.push({
                id: data[i].id,
                nazione: data[i].country,
                data_ora: moment(data[i].date_hour).format("DD/MM/YYYY HH:mm"),
                consulente: (data[i].consulent !== null) ? data[i].consulent.firstname + " " + data[i].consulent.lastname : "",
                provenienza: data[i].referral_platform,
                stato: data[i].status,
                modulo: data[i].module,
                nome: data[i].name,
                email: data[i].email,
                phone: data[i].phone
            })
        }

        const ws = XLSX.utils.json_to_sheet(leads);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const dataBlob = new Blob([excelBuffer], {type: fileType});

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(dataBlob);
        downloadLink.download = "leads_" + moment().format('DD-MM-YYYY_HH-mm') + fileExtension;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }


    render() {
        let errors = this.state.errors;
        let role = Utils.getUserData().role;
        const countries = Utils.printListCountries();
        const self = this;
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista leads.</Alert>
            </React.Fragment>
        }
        return (
            <React.Fragment>
                <LeadSetting leads={(this.state.leadsFiltered !== null) ? this.state.leadsFiltered : this.state.leads} onReload={this.getLeads.bind(this)} onFilterLeads={this.onFilterLeads.bind(this)} />
                <Row>
                    <Col style={{ padding: 10 }}>
                        <div className="flex-shrink-0" >
                            <LeadAdd modules={this.state.modules} getLeads={() => this.getLeads()} />
                            {(role === "ADMIN") ? <Button hidden={!Utils.getUserData().permissions.includes("LEAD_MODULE")} color='info' size="sm" style={{margin: 5}} onClick={() => this.setState({ importModal: true })}>
                                <i className="ri-download-2-line me-1"></i>
                                IMPORTA
                            </Button> : null}
                            <Button color='primary' size="sm" style={{margin: 5}} onClick={() => this.exportToExcel()} disabled={this.state.leads.length === 0}>
                                <i className="ri-file-excel-2-fill me-1"></i>
                                ESPORTA
                            </Button>
                        </div>
                    </Col>

                    {(role === "ADMIN" && this.selectedLeads.length === 0) ? <Col style={{ margin: 10 }}>
                        <div className="d-flex justify-content-start" style={{maxWidth: "300px"}}>
                            <Select
                                value={this.state.filterConsulent}
                                isMulti
                                isClearable
                                isSearchable
                                name="Consulenti"
                                placeholder="Filtra consulenti..."
                                options={this.state.consulent.filter((c) => c.active)}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                closeMenuOnSelect={false}
                                onChange={(val) => {
                                    this.setState({
                                        filterConsulent: (val !== null) ? val : []
                                    })
                                    this.filterLeadsOnConsulent(val.map((v) => v.id))
                                }}
                                getOptionLabel={(opt) => opt.firstname + ' ' + opt.lastname}
                                getOptionValue={(opt) => opt.firstname + ' ' + opt.lastname}
                            />
                        </div>
                    </Col> : null}

                    {(role === "ADMIN" && this.selectedLeads.length > 0) ? <Col style={{ padding: 10 }}>
                        <div className="d-flex justify-content-end">
                            <Select
                                value={this.state.selectedConsulent}
                                options={this.state.consulent}
                                name="choices-single-c"
                                placeholder="Assegna consulente"
                                className='me-5 w-50'
                                onChange={(val) => {
                                    this.setState({
                                        selectedConsulent: val
                                    })
                                }}
                                getOptionLabel={(opt) => opt.firstname + ' ' + opt.lastname}
                                getOptionValue={(opt) => opt.firstname + ' ' + opt.lastname}
                            ></Select>
                            <Button color='success' className='me-2' size="sm" onClick={this.onAssignLead}>
                                <i className="ri-links-fill me-1"></i>
                                ASSEGNA
                            </Button>
                            <Button color='danger' size="sm" onClick={this.onRemoveAssignedLead}>
                                <i className="ri-shield-cross-line me-1"></i>
                                RIMUOVI
                            </Button>
                            {/* <AssignLead selectedLeads={this.selectedLeads} onResolve={this.getLeads.bind(this)} minWidth={'250px'}/> */}
                        </div>
                    </Col> : null}
                </Row>

                {(this.state.reminders.length > 0) ? <Row>
                    <Col>
                        {this.state.reminders.map((reminder, i) => {
                            return <Alert color="primary" key={i}><Button size="sm" color="success" onClick={() => this.checkReminder(reminder.id)}><i className="ri-check-fill"></i></Button>&nbsp;&nbsp;&nbsp;<Link to={"/lead/detail/" + reminder.Lead.id}><strong>APPUNTAMENTO</strong> {moment(reminder.date_hour).fromNow()} con <strong>{reminder.Lead.name}</strong>: {reminder.note}</Link></Alert>
                        })}
                    </Col>
                </Row> : null}

                {(this.state.loading) ? <Loading /> :
                    <LeadTableRender onSelectLead={this.onSelectLead} leads={(this.state.leadsFiltered !== null) ? this.state.leadsFiltered : this.state.leads} leadIds={this.state.leadIds} getReferralLabel={this.getReferralLabel} getLeads={this.getLeads.bind(this)} signCheck={this.signCheck.bind(this)}/>
                }

                <Modal style={{ overflowY: "auto" }} isOpen={this.state.importModal} scrollable={true} centered className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-4'>
                            <h3> IMPORTA CSV DA BUSINESS MANAGER</h3>
                        </div>
                        {(this.state.loadingImport) ? <Loading /> : <React.Fragment>
                            <FormGroup>
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Modulo*
                                    </Label>
                                    <Select
                                        value={this.state.moduleUpload}
                                        options={this.state.modules.map((m) => {
                                            return {
                                                value: m.id,
                                                label: m.name,
                                                icon: (m.referral === "META") ? "ri-facebook-box-fill align-middle fs-20 text-secondary" : "ri-global-fill align-middle fs-20 text-primary"
                                            }
                                        })}
                                        onChange={(val) => this.setState({moduleUpload: val})}
                                        formatOptionLabel={referral => (
                                            <div className="referral-option">
                                                <i className={referral.icon} />
                                                <span>&nbsp;&nbsp;{referral.label}</span>
                                            </div>
                                        )}
                                    ></Select>
                                    {/*<Input type="text" hidden invalid={this.state.moduleUpload === null} />*/}
                                </div>
                                <Input
                                    id="exampleFile"
                                    name="file"
                                    type="file"
                                    accept=".csv"
                                    disabled={(this.state.moduleUpload === null)}
                                    onChange={(e) => this.uploadLeadFile(e.target.files)}
                                />
                            </FormGroup>
                            <div className='text-center mb-4'>
                                <i>Solo file di tipo .csv</i>
                            </div>
                            <div className='text-center'>
                                <h6>ATTENZIONE: sono ammessi solo dati provenienti da Business Manager (non modificare il file originale)</h6>
                            </div>
                        </React.Fragment>}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='danger'
                            onClick={() => this.setState({ importModal: false, moduleUpload: null })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(LeadTable);
